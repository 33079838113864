import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogCard = ({ title, image, uri, excerpt, imageAlt }) => {
	return (
		<Col lg={6} xl={4}>
			<Card
				style={{
					boxShadow: "0px 3px 6px #00000029",
					borderRadius: "5px",
					opacity: 1,
				}}
				className="w-100 mb-5"
			>
				<Card.Img as={GatsbyImage} variant="top" image={image} alt={imageAlt} />
				<Card.Body>
					<Card.Title className="jost-bold">{title}</Card.Title>
					<Card.Text>
						<div
							id="blog-text"
							className="d-block blog-card-text"
							dangerouslySetInnerHTML={{ __html: excerpt }}
						></div>
					</Card.Text>
					<Col xs={12} className="text-start">
						<Link className="pink-link cta-link" to={`${uri}`}>
							Read More
						</Link>
					</Col>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default BlogCard;
