import React, { useState, useContext, useRef } from "react";
import { graphql } from "gatsby";
import { ImageGallery } from "../components/imageGallery";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import ImageThumbnail from "../components/imageThumbnail";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "../components/link";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import SVGSVGRingCircle from "../components/SVG/ring-circle";
import SVGThinRing from "../components/SVG/thin-ring";
import Button from "react-bootstrap/Button";

const BlogPostTemplate = ({
	data: { class1, SWImg, site, LGCImg, BSImg, rImg },
}) => {
	const [selectedImage, setSelectedImage] = useState(
		class1?.classFields.featuredImage.node?.localFile.childImageSharp
			.gatsbyImageData
	);
	const SWImage = SWImg.localFile.childImageSharp.gatsbyImageData;
	const LGCImage = LGCImg.localFile.childImageSharp.gatsbyImageData;
	const BSImage = BSImg.localFile.childImageSharp.gatsbyImageData;
	const rImage = rImg.localFile.childImageSharp.gatsbyImageData;
	const handleClick = (image) => {
		setSelectedImage(image);
	};
	const siteUrl = site.siteMetadata.siteUrl;
	const list121 = class1?.classFields.onetooneLinks;
	const bookLink = list121 ? "#121list" : "/contact-us";
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/classes`,
					id: `${siteUrl}/classes`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${class1.title}`,
				item: {
					url: `${siteUrl}${class1.uri}`,
					id: `${siteUrl}${class1.uri}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<section
				style={{
					background:
						"transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
					opacity: 1,
				}}
			>
				<Navigation background="none" />
				<Container>
					{" "}
					<Row>
						<Col>
							<h1 className="text-primary mt-8 mb-6 text-center display-4 jost-bold">
								{class1.title}
							</h1>
						</Col>
					</Row>
				</Container>
			</section>
			<Container className="mb-5">
				<GatsbySeo
					title={`${class1.title}`}
					description={`${class1.title}`}
					language="en"
					openGraph={{
						title: `${class1.title}`,
						description: `${class1.title}`,
						url: `${siteUrl}${class1.uri}`,
						type: "article",
						images: [
							{
								url: `${class1.classFields.featuredImage.node?.localFile.publicURL}`,
								width: `${class1.classFields?.featuredImage.node?.localFile.childImageSharp.original.width}`,
								height: `${class1.classFields?.featuredImage.node?.localFile.childImageSharp.original.height}`,
								alt: `${class1.title}`,
							},
						],
					}}
				/>
				<Row className="justify-content-center position-relative">
					<SVGSVGRingCircle
						style={{ zIndex: -1 }}
						className="position-absolute bottom-0 end--10 d-none d-xl-block"
					/>
					<Col lg={6}>
						<section>
							<ImageGallery
								imageMain={
									class1?.classFields.featuredImage.node?.localFile
										.childImageSharp.gatsbyImageData
								}
								image={selectedImage}
							/>
							<Row className="mt-4">
								<Col className="h-100" xs={4}>
									<ImageThumbnail
										onClick={handleClick}
										image={
											class1?.classFields.thumbnailImage1?.node.localFile
												.childImageSharp.gatsbyImageData
										}
									/>
								</Col>
								<Col className="h-100" xs={4}>
									{" "}
									<ImageThumbnail
										onClick={handleClick}
										image={
											class1?.classFields.thumbnailImage2.node?.localFile
												.childImageSharp.gatsbyImageData
										}
									/>
								</Col>
								<Col className="h-100" xs={4}>
									{" "}
									<ImageThumbnail
										onClick={handleClick}
										image={
											class1?.classFields.thumbnailImage3.node?.localFile
												.childImageSharp.gatsbyImageData
										}
									/>
								</Col>
							</Row>
						</section>
					</Col>
					<Col className="ps-lg-6 pt-lg-7 pt-5" lg={6}>
						<p className=" mb-0 jost-bold">When</p>
						<Link to="/timetable" className="text-decoration-none">
							{" "}
							<span className="text-secondary">
								{parse(class1?.classFields.when)}
							</span>{" "}
						</Link>

						<p className="mb-0 mt-4 jost-bold">Locations</p>
						<Link to="/contact-us#locations" className="text-decoration-none">
							{" "}
							<p className="text-secondary">
								{class1?.classFields.locations}
							</p>{" "}
						</Link>
						<Row className="mt-5">
							<Col>
								<Button
									variant="button"
									className={`cta-btn px-4 mb-3 mb-md-0 w-100 w-md-auto me-4 ${
										list121 ? "" : "d-none"
									}`}
									as={Link}
									href="#121list"
								>
									Book Class
								</Button>
								<Button
									variant="button"
									className={`cta-btn px-4 mb-3 mb-md-0 w-100 w-md-auto me-4 ${
										list121 ? "d-none" : ""
									}`}
									as={Link}
									to="/contact-us"
								>
									Book Class
								</Button>
								<Button
									className="cta-btn px-4 w-100 w-md-auto"
									as={Link}
									to="/free-assessment"
								>
									Book Free Assessment
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="mt-4 mt-xl-7">
					<Col xs={12} xl={6}>
						{" "}
						<div className="blog-post-content">{parse(class1.content)}</div>
						<Row id="121list" className={` pt-3 ${list121 ? "" : "d-none"}`}>
							{" "}
							{/* <Col className="text-center mt-4 mt-xl-0" md={6} lg={6} xl={6}>
								<Link
									className="cta-link"
									to="https://eastbournepilates.as.me/reformer"
								>
									{" "}
									<GatsbyImage
										style={{ width: "100%", height: "280px" }}
										image={rImage}
										alt={rImg?.altText}
									/>
									<p className="fs-4 text-primary mt-3 mb-0">Reformer</p>
									<p className="fs-4 jost-bold contact-link">Book Now</p>
								</Link>
							</Col> */}
							<Col className="text-center mt-4 mt-xl-0" md={6} lg={6} xl={6}>
								<Link
									className="cta-link"
									to="https://eastbournepilates.as.me/1to1"
								>
									{" "}
									<GatsbyImage
										style={{ width: "100%", height: "280px" }}
										image={BSImage}
										alt={BSImg?.altText}
									/>
									<p className="fs-4 text-primary mt-3 mb-0">One To One</p>
									<p className="fs-4 jost-bold contact-link">Book Now</p>
								</Link>
							</Col>
						</Row>
					</Col>
					<Col
						className="ps-xl-5 mt-4 mt-xl-0 pb-4 pb-xl-0 position-relative"
						xl={6}
					>
						<SVGThinRing
							style={{ height: "30rem", width: "30rem", zIndex: -1 }}
							className={`position-absolute  d-none d-xl-block ${
								list121 ? "bottom--10 end--10" : "bottom--30 end--30"
							} `}
						/>
						<h2 className="text-primary">Instructor</h2>
						<Row>
							<Col lg={8}>
								<Row className="align-items-center">
									<Col xs={4}>
										{" "}
										<GatsbyImage alt={SWImg.altText} image={SWImage} />
									</Col>
									<Col xs={8}>
										<p className="jost-bold text-primary">Tracy Fuller</p>
										<Link className="cta-link" to="/about">
											Find out more about Tracy
										</Link>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query ClassById($id: String!) {
		SWImg: wpMediaItem(title: { eq: "St-Wilfrids" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		rImg: wpMediaItem(title: { eq: "Lottbridge-Golf-Course" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		LGCImg: wpMediaItem(title: { eq: "Home-Hero-Image-2" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		BSImg: wpMediaItem(title: { eq: "One to one studio" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site: site {
			siteMetadata {
				siteUrl
			}
		}
		class1: wpClass(id: { eq: $id }) {
			content
			id
			title
			classFields {
				onetooneLinks
				locations
				tag
				when
				featuredImage {
					node {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
								)
							}
						}
					}
				}
				thumbnailImage1 {
					node {
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
								)
							}
						}
					}
				}
				thumbnailImage2 {
					node {
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
								)
							}
						}
					}
				}

				thumbnailImage3 {
					node {
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
								)
							}
						}
					}
				}
			}
		}
	}
`;
